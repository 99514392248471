@font-face {
  font-family: "Finlandica";
  src: url("app/assets/fonts/hinted-subset-Finlandica-BoldItalic.woff2")
      format("woff2"),
    url("app/assets/fonts/hinted-subset-Finlandica-BoldItalic.woff")
      format("woff"),
    url("app/assets/fonts/hinted-subset-Finlandica-BoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Finlandica";
  src: url("app/assets/fonts/hinted-subset-Finlandica-Bold.woff2")
      format("woff2"),
    url("app/assets/fonts/hinted-subset-Finlandica-Bold.woff") format("woff"),
    url("app/assets/fonts/hinted-subset-Finlandica-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Finlandica";
  src: url("app/assets/fonts/hinted-subset-Finlandica-Italic.woff2")
      format("woff2"),
    url("app/assets/fonts/hinted-subset-Finlandica-Italic.woff") format("woff"),
    url("app/assets/fonts/hinted-subset-Finlandica-Italic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Finlandica";
  src: url("app/assets/fonts/hinted-subset-Finlandica-Regular.woff2")
      format("woff2"),
    url("app/assets/fonts/hinted-subset-Finlandica-Regular.woff") format("woff"),
    url("app/assets/fonts/hinted-subset-Finlandica-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*html {*/
/*  height: 100%;*/
/*}*/

body {
  margin: 0;
  font-family: "Finlandica", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  overflow-x: hidden;
  height: 100%;
  width: 100vw;
}

#root {
  /*height: 100%;*/
  /*background-color: #f8f8f8;*/
}

a {
  text-decoration: initial;
  color: #002561;
}
a:hover {
  cursor: pointer;
  text-decoration: initial;
  color: #002561;
}

button:hover {
  cursor: pointer;
}

div {
  font-family: "Finlandica", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

#atlwdg-trigger {
  z-index: 4;
}

:focus {
  outline: 1px solid rgba(0, 0, 0, 0.1);
  filter: brightness(95%);
}
